import React, { ReactElement } from "react";
import {
  MarkdownContent,
  FixedSharpImageFile,
  AllFileQueryResult,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql, useStaticQuery } from "gatsby";
import ActivityBox from "../../components/activity-box";
import OurCampLayout from "../../components/our-camp-layout";

type Activity = {
  readonly title: string;
  readonly image: FluidSharpImageFile;
  readonly description: string;
  readonly qualificationNeeded: boolean;
};

type Data = AllFileQueryResult<Activity> & {
  readonly bannerHeaderImage: FixedSharpImageFile;
};

export default function OurCampActivitiesPage(): ReactElement {
  const data = useStaticQuery<Data>(graphql`
    {
      bannerHeaderImage: file(
        relativePath: { eq: "img/camp-activities-bg.jpg" }
      ) {
        ...GeneralPageLayout_bannerHeaderImage
      }
      allFile(
        filter: { relativeDirectory: { eq: "activities" } }
        sort: { order: ASC, fields: childMarkdownRemark___frontmatter___title }
      ) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              title
              description
              qualificationNeeded
              image {
                childImageSharp {
                  fluid(maxWidth: 242, maxHeight: 212) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <OurCampLayout
      meta={{
        title: "Activities",
        description:
          "Camp Yarramundi offers a wide variety of adventure activities that are all focused around the Outdoor Education & Camping Values.",
      }}
      bannerHeaderImage={data.bannerHeaderImage}
    >
      <div className="content-block">
        <h2>Adventure Activities</h2>
        <p>
          Camp Yarramundi offers a wide variety of adventure activities that are
          all focused around the Outdoor Education & Camping Values.
        </p>
      </div>

      <div className="content-block" style={{ color: "#ed353a" }}>
        <p>
          Due to significant flood damage, we regret to inform you that we are
          no longer able to offer river activities at this time.
        </p>
        <p>
          We appreciate your understanding and patience as we navigate through
          this challenging period.
        </p>
        <p>
          We will keep you updated on any changes and hope to resume river
          activities as soon as it is safe to do so.
        </p>
      </div>

      <div className="gallery-boxes">
        <div className="row">
          {data.allFile.nodes.map((node) => (
            <ActivityBox
              key={node.childMarkdownRemark.frontmatter.title}
              qualificationNeeded={
                node.childMarkdownRemark.frontmatter.qualificationNeeded
              }
              title={node.childMarkdownRemark.frontmatter.title}
              image={
                node.childMarkdownRemark.frontmatter.image.childImageSharp.fluid
              }
            >
              <MarkdownContent>
                {node.childMarkdownRemark.frontmatter.description}
              </MarkdownContent>
            </ActivityBox>
          ))}
        </div>
      </div>

      <p>
        All activities NOT marked with an{" "}
        <span className="asterisk red-color">*</span> must be run by qualified
        camp instructors. Guests wishing to use the pool without YMCA instructor
        supervision must provide evidence of a CPR qualification.
      </p>
    </OurCampLayout>
  );
}
